import constants from '@/constants';
import { decimal } from '@/utils/commonUtils/utils';

const sum = (arrayVariables) => {
  return arrayVariables.reduce((sum, item) => {
    return sum.plus(item);
  }, decimal(0));
};

export default {
  isUserLoaded() {
    if (this.state.user) {
      return !!this.state.user.appAuthToken;
    }
    return false;
  },
  isFirstIncomeAt() {
    // Пользователь сделал первый перевод и таким образом подтвердил себя
    return !!this.state.user.appAuthToken && this.state.user.firstIncomeAt;
  },
  isAuthenticated() {
    return !!this.state.session?.token;
  },
  isUnauthenticated() {
    return !this.isAuthenticated;
  },
  isUserRegistrated() {
    // Пользователь завершил регистрацию
    return this.state.user.name && this.state.user.isOnboardingComplete;
  },
  isAdmin() {
    if (this.state.user.isAdmin || this.state.user.isAdminSuper) {
      return true;
    }
    return false;
  },
  isAlfaCustomer() {
    return this.state.user.isAlfaCustomer;
  },
  isGosuslugiCustomer() {
    return this.state.user.userSource === 'publicServices';
  },
  isNewAnalyticsEnabled() {
    return this.state.user.isNewAnalyticsEnabled;
  },
  userFullName() {
    const { user } = this.state;
    return [user.surname, user.name, user.secondName].join(' ').trim();
  },
  wasUserApprovedByUprid() {
    return Boolean(this.userFullName);
  },
  isSecondaryMarketOperator() {
    return this.selectedCompany?.isSecondaryMarketOperator;
  },
  isInvestmentOperation() {
    return (
      this.state.buffer.depositOperationId ===
      constants.depositOperations.investment
    );
  },
  isProjectViewOperation() {
    return (
      this.state.buffer.depositOperationId ===
      constants.depositOperations.project
    );
  },
  isRefillingOperation() {
    return (
      this.state.buffer.depositOperationId ===
      constants.depositOperations.refill
    );
  },
  isSigningEulaOperation() {
    return (
      this.state.buffer.depositOperationId ===
      constants.depositOperations.signingEula
    );
  },
  selectedCompany() {
    const { companies } = this.state.dictionaries;
    const { companyId } = this.state.preferences;
    return companies.find((item) => item.id === companyId);
  },
  selectedCompanyId() {
    /**
     * Возвращает сохраненую ранее CompmanyId
     * с проверкой наличия данного id в списке companies
     */
    const { companies } = this.state.dictionaries;
    const { companyId } = this.state.preferences;
    const company = companies.find((item) => item.id === companyId);
    if (company) {
      return company.id;
    }
    // если существуют companyId и он не входит в список companies
    // то понимает что он не верный и обнуляем его
    return null;
  },
  firstIncomeAt() {
    return this.state.user.firstIncomeAt;
  },
  isSelectedCompanyPerson() {
    return this.selectedCompany.typeId === constants.COMPANY_TYPES_PERSON;
  },
  companies() {
    return this.state.dictionaries.companies;
  },
  isPotokHoldingCompany() {
    // Используется во вкладке Мои инвестиции, для определения пользователя Поток Холдинг
    const { companies } = this.state.dictionaries;
    const { companyId } = this.state.preferences;
    const company = companies.find((item) => item.id === companyId);
    if (company) {
      return company.isPotokHoldingCompany;
    }
  },
  isPotokFinanceBorrower() {
    // Используется во вкладке Мои инвестиции, для определения пользователя Поток Холдинг
    const { companies } = this.state.dictionaries;
    const { companyId } = this.state.preferences;
    const company = companies.find((item) => item.id === companyId);
    if (company) {
      return company.isPotokFinanceCompany;
    }
  },
  companiesRoot() {
    if (!this.state.dictionaries.companies) {
      return [];
    }
    return this.state.dictionaries.companies.filter((i) => !i.parentId);
  },

  /**
   * Возвращает Баланс в компоненте Balance
   */
  getBalance() {
    const getFreeBalance = this.getFreeBalance ? this.getFreeBalance : 0;
    const getCashboxRealAmount = this.getCashboxRealAmount
      ? this.getCashboxRealAmount
      : 0;
    const getReserved = this.getReserved ? this.getReserved : 0;
    const activeBalance = this.getActiveBalance ? this.getActiveBalance : 0;
    let values = [
      getFreeBalance,
      getCashboxRealAmount,
      getReserved,
      activeBalance,
    ];
    return sum(values).toNumber();
  },
  /**
   * Возвращает Свободно в компоненте Balance
   */
  getFreeBalance() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      const balance = this.state.balances[companyId].investor;
      return balance.freeWithoutCashbox;
    }
    return 0;
  },
  /**
   * Возвращает Копилка в компоненте Balance
   */
  getCashboxRealAmount() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      const balance = this.state.balances[companyId].investor;
      return balance.cashboxAmount;
    }
    return 0;
  },
  /**
   * Возвращает Активный портфель в компоненте Balance
   */
  getActiveBalance() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      return this.state.balances[companyId].investor.activeBalance;
    }
    return 0;
  },
  getActiveBalanceFull() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      return this.state.balances[companyId].investor;
    }
    return null;
  },
  getActiveBalanceAuto() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      return this.state.balances[companyId].investor.activeBalanceAuto;
    }
    return 0;
  },
  getActiveBalanceManual() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      return this.state.balances[companyId].investor.activeBalanceManual;
    }
    return 0;
  },
  /**
   * Возвращает Резерв в компоненте Balance
   */
  getReserved() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      return this.state.balances[companyId].investor.reserved;
    }
    return 0;
  },
  getFreeWithoutCashbox() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      return this.state.balances[companyId].investor.freeWithoutCashbox;
    }
    return 0;
  },
  reservedBalance() {
    const id = this.state.preferences.companyId;
    if (
      this.state.balances &&
      this.state.balances[id] &&
      this.state.balances[id].investor
    ) {
      return this.state.balances[id].investor.reserved;
    }
    return undefined;
  },
  investorFreeBalance() {
    const id = this.state.preferences.companyId;
    if (
      this.state.balances &&
      this.state.balances[id] &&
      this.state.balances[id].investor
    ) {
      return this.state.balances[id].investor.free;
    }
    return undefined;
  },
  /**
   * Возвращает Целевое значение копилки в компоненте Balance
   */
  getCashboxLimitAmount() {
    const companyId = this.state.preferences.companyId;
    if (
      companyId &&
      this.state.balances &&
      this.state.balances[companyId] &&
      this.state.balances[companyId].investor
    ) {
      const balance = this.state.balances[companyId].investor;
      return balance.cashboxLimitAmount;
    }
    return 0;
  },
  /**
   * Возвращает список лицевых счетов (список компаний) по выбранной родительской компании
   */
  getAccountList() {
    let items = [];
    let parentId = null;
    const companyId = this.state.preferences.companyId;
    const companies = this.state.dictionaries.companies;
    const selectedCompany = companies.find((item) => item.id === companyId);

    if (!selectedCompany) {
      return;
    }

    if (selectedCompany.parentId) {
      parentId = selectedCompany.parentId;
      items.push(companies.find((i) => i.id === selectedCompany.parentId));
    }
    if (!selectedCompany.parentId) {
      items.push(companies.find((i) => i.id === selectedCompany.id));
    }

    companies.forEach((item) => {
      if (parentId) {
        if (item.parentId === parentId) {
          items.push(item);
        }
      } else {
        if (item.parentId === selectedCompany.id) {
          items.push(item);
        }
      }
    });
    return items;
  },
  /**
   * Признак когда возможно квалификация у пользователя
   */
  isEnableQualifiaction() {
    if (!this.selectedCompany) {
      return false;
    }
    const selectedCompany = this.selectedCompany;
    const isSelectedCompanyPerson =
      selectedCompany.typeId === constants.companyTypes.person;
    return (
      isSelectedCompanyPerson &&
      (selectedCompany.qiStatus === constants.qiStatus.REJECTED ||
        selectedCompany.qiStatus === constants.qiStatus.NOT_QI ||
        selectedCompany.qiStatus === constants.qiStatus.EMPTY) &&
      (!this.state.preferences.companyId ||
        this.state.preferences.companyId === selectedCompany.id)
    );
  },
  isAutoInvest() {
    return this.selectedCompany.isAutoInvest;
  },
};
