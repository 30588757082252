<template>
  <div>
    <PotokDivider
      v-if="props.divider"
      :class="{
        only_mobile: props.divider_mobile_only,
      }"
      :marginY="isMobile ? 13 : 17"
    />

    <div class="row item_row">
      <div
        class="col-xs-12 item_name potok-text-body-2"
        :class="['col-md-' + props.nameWidth, 'col-sm-' + props.nameWidth]"
      >
        <template v-if="props.name">{{ props.name }}</template>
        <template v-else>
          <slot name="name"></slot>
        </template>
      </div>
      <div
        class="col-xs-12 item_value"
        :class="[
          'col-md-' + props.valueWidth,
          'col-sm-' + props.valueWidth,
          props.fieldError ? 'item_error' : '',
        ]"
      >
        <template v-if="props.value">
          <div v-html="props.value" />
        </template>
        <template v-else>
          <slot name="value"></slot>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import detectDevice from "@/composables/detectDeviceComposable";
import { PotokDivider } from "potok-uikit";
const { isMobile } = detectDevice();

const props = defineProps({
  name: {},
  value: {},
  divider: {
    type: Boolean,
    default: true,
  },
  divider_mobile_only: {
    type: Boolean,
    default: true,
  },
  nameWidth: {
    type: [String, Number],
    default: '4',
  },
  valueWidth: {
    type: [String, Number],
    default: '8',
  },
  fieldError: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss" scoped>
.item_row {
  margin-bottom: 15px;
  @media (max-width: $size_767) {
    margin-bottom: 4px;
  }
}

.item_name {
  color: $grey;
  padding-right: 0;
  display: flex;
  align-items: center;
  @media (max-width: $size_767) {
    padding-bottom: 5px;
  }
  p {
    margin: 0;
  }
  .v-popper {
    margin-left: 5px;
  }
}

.item_value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  word-break: break-word;
  text-transform: none;
  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 20px;
  }
}
.item_error {
  color: $error;
}
</style>
