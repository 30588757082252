<template>
  <section class="page">
    <h2 class="page_title">перевод между счетами инвестора</h2>
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-md-8 item">
          <div class="item_title">откуда</div>
          <AccountMenu
            v-model="selectedAccountFrom"
            :items="store.getAccountList"
            @change="changeValue"
          />
          <div v-if="selectedAccountFrom" class="item_amount">
            доступно
            <span
              class="available-amount"
              style="color: #04ccd9"
              @click="copyAvailableAmount"
            >
              <MoneyValue
                :value="getBalance"
                color="#04CCD9"
                comma-color="#04CCD9"
                style="display: inline"
              />
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-md-8 item" style="margin-top: 30px">
          <div class="item_title">куда</div>
          <AccountMenu
            v-model="selectedAccountTo"
            placeholder="выберите счет"
            :items="filteredAccountList(store.getAccountList)"
            @change="changeValue"
          />
          <input
            v-model="amountTransfer"
            class="form-control item_input"
            type="number"
            placeholder="сумма,₽"
          />
        </div>
      </div>
      <div class="potok-color-red" style="margin-top: 10px">
        {{ messageError }}
      </div>
    </div>
    <div class="actions">
      <PotokButton
        text="перевести"
        size="large"
        class="button-transfer"
        :disabled="loading"
        @click="onClickTransfer"
      />
      <button
        type="button"
        class="button button-primary button-grey-outline button-cancel"
        :disabled="loading"
        @click="onClickCancel"
      >
        отмена
      </button>
    </div>
  </section>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

import server from '@/server';
import AccountMenu from '../components/Transfer/TransferAccountMenu.vue';
import { useStateStore } from '@/store/stateStore';
import { PotokButton } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue';

const store = useStateStore();
const router = useRouter();

const selectedAccountFrom = ref(null);
const selectedAccountTo = ref(null);
const amountTransfer = ref(null);
const messageError = ref(null);
const loading = ref(false);

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

// const accountList = computed(() => {
//   return store.getAccountList
// })

const getBalance = computed(() => {
  if (!selectedAccountFrom.value) {
    return null;
  }
  const companyId = selectedAccountFrom.value.id;
  if (store.state.balances[companyId]) {
    return store.state.balances[companyId].investor.free;
  }
  return null;
});

const changeValue = () => {
  messageError.value = null;
};

const isValidTransfer = () => {
  if (!selectedAccountTo.value) {
    messageError.value = 'нужно выбрать счет для зачесления средств';
    return false;
  }
  if (!amountTransfer.value) {
    messageError.value = 'нужно указать сумму перевода';
    return false;
  }
  if (!getBalance.value || amountTransfer.value > getBalance.value) {
    messageError.value = 'недостаточно средств';
    return false;
  }
  return true;
};

const onClickTransfer = () => {
  if (!isValidTransfer()) {
    return;
  }
  const query = {
    fromCompanyId: selectedAccountFrom.value.id,
    toCompanyId: selectedAccountTo.value.id,
    amount: String(amountTransfer.value),
    transferType: 'internal',
  };
  loading.value = true;
  return server.postMoneyTransfer
    .send(query)
    .pipe(
      onPostMoneyTransferAsyncSuccess.bind(this, query),
      onPostMoneyTransferAsyncError.bind(this),
    )
    .exec();
};

const onPostMoneyTransferAsyncSuccess = () => {
  loading.value = false;
  store.fetchCompanies();

  router.push({
    name: 'main',
  });
};

const copyAvailableAmount = () => {
  amountTransfer.value = Math.floor(getBalance.value);
};

const onPostMoneyTransferAsyncError = (error) => {
  loading.value = false;
  const data = error.data;
  if (data.messages && data.messages.length > 0) {
    messageError.value = data.messages[0];
  }
};

const filteredAccountList = () => {
  if (!store.getAccountList || !selectedAccountFrom.value) {
    return [];
  }
  return store.getAccountList.filter((i) => {
    return i.id != selectedAccountFrom.value.id;
  });
};

const onClickCancel = () => {
  router.push({
    name: 'main',
  });
};

watch(
  () => {
    return selectedCompany.value;
  },
  () => {
    selectedAccountFrom.value = selectedCompany.value;
    selectedAccountTo.value = null;
    if (selectedAccountFrom.value) {
      store.fetchActiveBalance({
        companyId: selectedAccountFrom.value.id,
      });
    }
  },
);

watch(
  () => {
    return selectedAccountFrom.value;
  },
  () => {
    selectedAccountTo.value = null;
    const companyId = selectedAccountFrom.value.id;
    if (!store.state.balances[companyId]) {
      store.fetchActiveBalance({
        companyId: selectedAccountFrom.value.id,
      });
    }
  },
);

onMounted(() => {
  if (!selectedAccountFrom.value) {
    selectedAccountFrom.value = store.selectedCompany;
  }
});
</script>
<style lang="scss" scoped>
.content {
  margin-top: 30px;
  background: #ffffff;
  border-radius: 14px;
  padding-top: 22px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.actions {
  display: flex;
  margin-top: 20px;
  @media (max-width: $size_991) {
    flex-direction: column;
  }
}
.item_title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $breakwater;
  margin-bottom: 20px;
}
.item_amount {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $grey;
}
.item_input {
  margin-top: 20px;
  min-height: 60px;
  padding: 18px 20px;
  background: $background;
  border-radius: 8px;
  border: 0;
}
.button-transfer {
  margin-right: 20px;
  @media (max-width: $size_991) {
    margin-right: initial;
    margin-bottom: 10px;
  }
}
.button-cancel {
  min-width: 116px;
}
.available-amount {
  cursor: pointer;
}
</style>
