<template>
  <div>
    <PotokDivider v-if="props.divider" class="only_mobile" marginY="10" />
    <div class="row item_row">
      <div
        class="col-md-12 item_row_title"
        :class="{
          item_row_title_big: props.big,
        }"
      >
        {{ props.title }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokDivider } from 'potok-uikit'
const props = defineProps({
  title: {},
  divider: {
    default: true,
  },
  big: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss" scoped>
.item_row_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #10273d;
  margin: 20px 0;
  max-width: 860px;
  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 16px;
    margin: 17px 0;
  }
}
.item_row_title_big {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}
</style>
