<template>
  <div class="content-block item">
    <div class="item-head">
      <p class="item-account potok-text-uppercase">
        ЛС&nbsp;<span>{{ getAccountNumber }}</span>
      </p>
      <div class="item-toggle" @click="onClickShowMore">
        <div class="mr-2">
          {{ getTypeCompany }}
        </div>
        <i v-if="isShowMenuMore" class="fa-angle-up fa fa-angle-up" />
        <i v-if="!isShowMenuMore" class="fa-angle-up fa fa-angle-down" />
      </div>
    </div>
    <div class="item-status mt-2">
      <div class="item-status-name potok-text-h2-light">
        {{ getShortName }}
      </div>
      <DashboardStatus />
    </div>

    <div
      v-if="isShowMenuMore"
      class="item-info mt-6"
    >
      <FormField
        v-if="!isCompanyTypePerson"
        :name="'Полное название'"
        :divider="false"
        :value="item.fullName"
      />
      <FormField
        v-if="!isCompanyTypePerson"
        :name="'Сокращенное название'"
        :value="item.shortName"
      />
      <FormField
        v-if="!isCompanyTypePerson"
        :name="'ОГРН'"
        :value="item.ogrn"
      />
      <FormField :name="'ИНН'" :value="item.inn" />
      <FormField v-if="!isCompanyTypePerson" :name="'КПП'" :value="item.kpp" />
      <FormField
        :name="'Дата регистрации'"
        :value="formatDate(item.registerDateAt)"
      />
      <FormField
        v-if="!isCompanyTypePerson"
        :name="'Юридический адрес'"
        :value="item.formalAddress"
      />
      <FormField :name="'Телефон'" :value="`${formattedPhone}`" />
      <FormField
        v-if="!isCompanyTypePerson"
        :name="'ФИО директора'"
        :value="item.directorFio"
      />
      <div class="item-documents mt-4">
        <div
          v-for="(doc, index) in item.documents"
          :key="index"
          class="item-document content-block mt-2"
          @click="downloadSignedDocumentsAsync(doc)"
        >
          <div class="item-document-title">
            {{ formatDocumentTitle(doc) }}
          </div>
          <PotokImg
            :src="imageConstants.document"
            height="20"
            width="16"
            class="item-document-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import constants from '@/constants';
import {
  formatDate,
  formatLoginToPhoneNumber,
} from '@/utils/commonUtils/utils';
import imageConstants from "@/constants/imageConstants";
import FormField from '@/components/_generic/forms/FormField.vue';
import DashboardStatus from '@/components/Dashboard/DashboardTitle/DashboardStatuses.vue'
import documentUtils from '@/composables/documentsComposable';

const { formatDocumentTitle, downloadSignedDocumentsAsync } = documentUtils();

const props = defineProps(['item']);
const isShowMenuMore = ref(false);

const isCompanyTypePerson = computed(() => {
  return props.item.typeId === constants.COMPANY_TYPES_PERSON;
});
const getTypeCompany = computed(() => {
  if (props.item.typeId === constants.COMPANY_TYPES_PERSON) {
    return 'Физ. лицо';
  }
  if (props.item.typeId === constants.COMPANY_TYPES_ENTREPRENEUR) {
    return 'ИП';
  }
  if (props.item.typeId === constants.COMPANY_TYPES_COMPANY) {
    return 'Юр. лицо';
  }
  return '';
});
const getAccountNumber = computed(() => {
  return props.item.investorAccountNumber;
});
const getShortName = computed(() => {
  return props.item.shortName;
});
const formattedPhone = computed(() => {
  return formatLoginToPhoneNumber(props.item.phone);
});

const onClickShowMore = () => {
  isShowMenuMore.value = !isShowMenuMore.value;
};
</script>
<style lang="scss" scoped>
@import "src/scss/typography.scss";

.item {
  padding: 30px;
  box-shadow: none;
  @media (max-width: $size_767) {
    padding: 20px 14px;
  }
}
.item-head {
  display: flex;
  justify-content: space-between;
}
.item-status {
  width: 420px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.item-account {
  display: flex;
  align-items: center;
  margin: 0;
  color: $grey;
  text-transform: none;
}
.item-status-name {
  color: $deep_dive;
  text-transform: initial;
  @media (max-width: $size_767) {
    @include potok-text-body-1;
    max-width: 280px;
  }
}
.item-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $grey;
}
.item-info {
  @media (max-width: $size_767) {
    margin-top: 18px;
  }
}
.item-documents {
  display: flex;
  flex-wrap: wrap;
}
.item-document {
  position: relative;
  box-shadow: none;
  background: $background;
  width: calc(50% - 4px);
  padding-right: 40px;
  padding-bottom: 26px;
  cursor: pointer;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.item-document:nth-child(2n) {
  margin-left: 8px;
  @media (max-width: $size_767) {
    margin-left: 0;
  }
}
.item-document-icon {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
</style>
