<template>
  <div v-if="!isBannerTourFinished" class="welcome-tour-container">
    <template v-if="props.isMobile">
      <div class="welcome-tour-container-mobile">
        <PotokButton
          size="link"
          theme="linkIcon"
          text="как начать инвестировать?"
          @click="isShowModal = true"
        >
          <template #icon-after>
            <PotokImg
              :src="ImageConstants.arrowRightDeepdive"
              height="10"
              width="10"
              class="ml-5"
            />
          </template>
        </PotokButton>
      </div>
    </template>
    <template v-else>
      <PotokButton
        size="link"
        theme="linkIconUpload"
        text="как начать инвестировать?"
        @click="isShowModal = true"
      />
    </template>

    <ModalFull v-if="isShowModal" @close="isShowModal = false">
      <template #content>
        <div
          v-for="item in steps"
          :key="item.title"
          class="welcome-tour-modal-item mb-7"
        >
          <div class="welcome-tour-modal-item-header mb-3">
            <PotokImg
              v-if="item.isDone"
              :src="ImageConstants.stepDoneTiffany"
              height="14"
              width="14"
              class="mr-2 mt-2"
            />
            <div v-else class="welcome-tour-modal-item-pure-circle mr-2 mt-2" />

            <div class="potok-text-h2">
              {{ item.title }}
            </div>
          </div>
          <div class="welcome-tour-modal-item-text">
            {{ item.text }}
          </div>
        </div>
        <template v-if="isStepsDone">
          <PotokDivider marginY="28" />
          <div class="welcome-tour-modal-done-text potok-text-h1 mb-7">
            теперь
            <span class="welcome-tour-modal-done-text-tiffany">всё готово</span
            ><br />
            для инвестирования
          </div>
          <PotokButton
            text="поехали!"
            :loading="loadingFinishTourBanner"
            class="welcome-tour-modal-done-btn"
            @click="finishTourBanner"
          />
        </template>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import ImageConstants from '@/constants/imageConstants';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { PotokButton } from 'potok-uikit';

const store = useStateStore();

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const isShowModal = ref(false);
const loadingFinishTourBanner = ref(false);

const steps = ref([
  {
    title: 'дождитесь проверку паспорта',
    text: 'проверяем ваш паспорт, проверка проходит в рабочие дни с 9:00 до 18:00 по Москве',
    isDone: false,
  },
  {
    title: 'выберите стратегию',
    text:
      'выберите одну из стратегий, чтобы деньги начали работать без дополнительных действий ' +
      '(распределение всей внесенной суммы может занять от нескольких часов до 2-х недель) или инвестируйте вручную',
    isDone: false,
  },
  {
    title: 'пополните счёт',
    text: 'пополните счёт или дождитесь пока поступят деньги, если вы отправили их ранее',
    isDone: false,
  },
]);

const isStepsDone = computed(() => {
  return !steps.value.find((el) => !el.isDone);
});
const isBannerTourFinished = computed(() => {
  return store.state.user.isStartInvestPressed;
});

const getTourChecklist = () => {
  return server.getUserTourChecklist
    .send()
    .pipe(onFetchGetTourChecklistSuccess, () => {})
    .exec();
};

const onFetchGetTourChecklistSuccess = (obj) => {
  steps.value[0].isDone = obj.data.isPassportVerified;
  steps.value[1].isDone = obj.data.hasStrategyOrInvestment;
  steps.value[2].isDone = obj.data.hasSentMoney;
};

const finishTourBanner = () => {
  loadingFinishTourBanner.value = true;

  return server.postUserInvestingChecklistComplete
    .send({})
    .pipe(
      () => {
        loadingFinishTourBanner.value = false;
        isShowModal.value = false;
        store.setTourBannerCompleted(true);
      },
      () => {
        loadingFinishTourBanner.value = false;
      },
    )
    .exec();
};

if (!isBannerTourFinished.value) {
  getTourChecklist();
}
</script>

<style scoped lang="scss">
@import '@/scss/typography.scss';

.welcome-tour-container {
  display: flex;
  align-items: center;
}
.welcome-tour-container-mobile {
  padding: 20px;
  border-radius: 14px;
  background: $white;
}
.welcome-tour-modal-item {
  &:last-child {
    margin-bottom: 0;
  }
}
.welcome-tour-modal-item-header {
  display: flex;
  align-items: flex-start;
}
.welcome-tour-modal-item-text {
  padding-left: 24px;
}
.welcome-tour-modal-item-pure-circle {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: $input;
}
.welcome-tour-modal-done-text {
  text-align: center;
  @media (max-width: $size_767) {
    @include potok-text-h2;
  }
}
.welcome-tour-modal-done-text-tiffany {
  color: $tiffany;
}
.welcome-tour-modal-done-btn {
  margin: 0 auto;
}
</style>
