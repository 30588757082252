<template>
  <div class="balances_result">
    <Loader v-if="props.loading" />
    <div v-if="!props.loading">
      <div class="content_title">
        <div>
          <VDropdown placement="bottom-start">
            <div class="title-icon content_title_text">Ваш результат</div>
            <template #popper>
              <div class="popup">
                <div v-close-popper class="popup-close"></div>
                <div class="popup-text" v-html="resultHelp"></div>
              </div>
            </template>
          </VDropdown>
          <p v-if="!getResultMessage" class="content_main_value">
            <span>{{ getProfitability }} </span>%
          </p>
        </div>
        <MenuSelect
          v-if="!getResultMessage"
          v-model="localSelectedResultPeriodValue"
          :items="resultPeriod"
          :align_right="true"
          :design="'primary'"
        />
      </div>

      <div v-if="getResultMessage" style="margin-top: 12px">
        <div
          class="content_main_value"
          style="margin-bottom: 18px; margin-top: -2px"
        >
          недостаточно данных
        </div>
        <p
          class="potok-text-body-1 potok-color-deepdive balances-result-no-data-text"
        >
          Расчёт появится после 1 месяца инвестирования
        </p>
      </div>
      <div v-if="!getResultMessage" class="content_block_items">
        <div class="item_row">
          <div class="item_title potok-text-body-2 potok-color-deepdive">
            <VDropdown placement="bottom-start">
              <div class="title-icon">
                {{ isNewResultEnabled ? 'доход' : 'процентный доход' }}
              </div>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    {{
                      isNewResultEnabled
                        ? 'Выплаченные проценты и пени, а также начисленные бонусы за выбранный период по всем инвестициям,кроме дефолтных'
                        : 'Выплаченные проценты и пени, начисленные бонусы за выбранный период по всем инвестициям, кроме дефолтных, а также еще не выплаченный накопленный процентный доход.'
                    }}
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
          <div
            class="item_value potok-text-body-1 potok-color-deepdive"
            style="margin-top: 5px"
          >
            <span>{{ formatCurrency(getInterestWithPenalty, 0) }} </span>&nbsp;₽
          </div>
        </div>

        <PotokDivider marginY="12" />

        <div class="item_row">
          <div class="item_title potok-text-body-2 potok-color-deepdive">
            <VDropdown placement="bottom-start">
              <div class="title-icon">
                {{ isNewResultEnabled ? 'потери' : 'чистый дефолт' }}
              </div>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Сумма остатка основного долга Инвестиций, вышедших в дефолт
                    за выбранный период за вычетом фактических возвратов по всем
                    дефолтным Инвестициям за тот же период.
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
          <MoneyValue
            :value="getLosses"
            rounding
            class="potok-text-body-1 mt-1"
          />
        </div>

        <PotokDivider marginY="12" />

        <div class="item_row">
          <div class="item_title potok-text-body-2 potok-color-deepdive">
            <VDropdown placement="bottom-start">
              <div class="title-icon">средний портфель</div>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Средний портфель за выбранный период на платформе.
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
          <div
            class="item_value potok-text-body-1 potok-color-deepdive"
            style="margin-top: 5px"
          >
            <span
              >{{ formatCurrency(getPortfolioWoDefaultPrincipalMean, 0) }}
            </span>
            &nbsp;₽
          </div>
        </div>
      </div>
    </div>
    <div>
      <router-link
        class="button button-secondary-outline button-balances"
        :to="{
          name: 'loans',
        }"
      >
        инвестировать
      </router-link>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { Loader } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue.vue';
import { useStateStore } from '@/store/stateStore';

import { formatCurrency } from '@/utils/commonUtils/utils';
import { parseISO, getYear, getMonth } from 'date-fns';

import MenuSelect from '@/components/_generic/cselect/MenuSelect.vue';
import { useRoute } from 'vue-router';

const store = useStateStore();
const route = useRoute();

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const localSelectedResultPeriodValue = ref(12);
const resultPeriod = ref([
  {
    value: 3,
    title: '3 месяца',
  },
  {
    value: 6,
    title: '6 месяцев',
  },
  {
    value: 9,
    title: '9 месяцев',
  },
  {
    value: 12,
    title: '12 месяцев',
  },
  {
    value: 0,
    title: 'За все время',
  },
]);
const resultHelp = ref(null);

const isNewResultEnabled = computed(() => {
  return (
    (store.isAdmin || store.isNewAnalyticsEnabled) &&
    route.query.isNewResultEnabled
  );
});
const getResultMessage = computed(() => {
  if (!store.state.result[store.selectedCompanyId]) {
    return null;
  }
  const _result = store.state.result[store.selectedCompanyId];
  if (_result && _result.error && _result.message) {
    return _result.message;
  }
  return null;
});
const getResult = computed(() => {
  if (!store.state.result[store.selectedCompanyId]) {
    return null;
  }
  const _result = store.state.result[store.selectedCompanyId];

  if (!_result || !_result.profitability) {
    return null;
  }
  const data = _result.profitability.find((i) => {
    return i.periodLength === localSelectedResultPeriodValue.value;
  });
  if (!data) {
    return null;
  }
  return data.data;
});
const getProfitability = computed(() => {
  const _result = getResult.value;
  if (!_result || !_result.profitability) {
    return;
  }
  return _result.profitability?.toString()?.split('.')?.join(',');
});
const getInterestWithPenalty = computed(() => {
  const _result = getResult.value;
  if (!_result) {
    return;
  }
  return _result.interestWithPenalty;
});
const getLosses = computed(() => {
  const _result = getResult.value;
  if (!_result) {
    return;
  }

  return isNewResultEnabled.value
    ? _result.losses * -1
    : _result.defaultDelta * -1;
});
const getPortfolioWoDefaultPrincipalMean = computed(() => {
  const _result = getResult.value;
  if (!_result) {
    return;
  }

  if (
    Object.prototype.hasOwnProperty.call(
      _result,
      'portfolioWoDefaultPrincipalMean',
    )
  ) {
    return _result.portfolioWoDefaultPrincipalMean;
  }

  return _result.portfolioWoDefaultAvg;
});
const getMonthYear = computed(() => {
  if (!store.state.resultDate) {
    return null;
  }
  const date = parseISO(store.state.resultDate);
  const months = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июль',
    7: 'августа',
    8: 'сентября',
    9: 'октября',
    10: 'ноября',
    11: 'декабря',
  };
  let currentYear = getYear(date);
  let currentMonth = getMonth(date);
  return `${months[currentMonth]} ${currentYear}`;
});

const generateMonthYear = () => {
  if (store.state.resultDate) {
    resultHelp.value = `
      Доход после дефолтов деленный на Средний портфель,
      приведенный к годовой ставке по формуле:
      <p>(1 + <span style="color:#4b63c4;"><sup>Д</sup>/<sub>П</sub></span>)
        <span style="color:#04ccd9;"><sup><sup><sup>12</sup>/<sub>n</sub></sup></sup></span> - 1,
      </p>
      где Д - доход после дефолтов, П - Средний портфель, n — число месяцев в выбранном периоде.
      Показатели рассчитаны на конец ${getMonthYear.value} года. Включает НДФЛ — для физ.лиц.
      `;
  }
};

watch(
  () => {
    return store.state.resultDate;
  },
  () => {
    generateMonthYear();
  },
);

onMounted(() => {
  if (store.isAdmin) {
    localSelectedResultPeriodValue.value = 0;
  }
  generateMonthYear();
});
</script>
<style lang="scss" scoped>
.balances_result {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: $size_767) {
    padding-bottom: 10px;
  }
}

.balances-result-no-data-text {
  color: $grey;
}

.button-balances {
  position: absolute;
  bottom: 10px;
}
</style>
