<template>
  <div class="all-project-item content-block">
    <div class="block-link">
      <div class="project_details">
        <LoansItemTags :item="localItem" />

        <PotokDivider marginY="12" />

        <div class="item_number_wrapper">
          <div class="item_number">
            <span
              >{{ localItem.number }} от
              {{ formatDate(localItem.fundsStartedAt, 'dd.MM.yyyy') }}
            </span>
          </div>
          <div v-if="localItem.pifReservedAmount">
            <pif-invested />
          </div>
        </div>
      </div>

      <div>
        <router-link
          ref="titleEl"
          class="project-title"
          :to="
            !isUserRegistrated
              ? {
                  name: 'main',
                }
              : {
                  name: 'loan',
                  params: {
                    id: localItem.id,
                  },
                }
          "
          @click="clickAnalytic()"
        >
          <template
            v-if="localItem.loanType === 'factoring' && localItem.promoParams"
          >
            <div :title="localItem.borrower.shortName">
              Заем под залог права требования
              {{ localItem.borrower.shortName }} к
              {{ localItem.promoParams.debtorTitle }}
            </div>
          </template>
          <template
            v-else-if="
              localItem.loanType === 'hidden_factoring' && localItem.promoParams
            "
          >
            <div :title="localItem.borrower.shortName">
              Заем под залог будущих прав по договору факторинга
              {{ localItem.borrower.shortName }} к
              {{ localItem.promoParams.supplierTitle }}
            </div>
          </template>
          <template v-else>
            <div :title="localItem.borrower.shortName">
              {{ localItem.borrower.shortName }}
            </div>
          </template>
        </router-link>

        <template
          v-if="!isFullRaised(localItem.loanAmount, localItem.raisedAmount)"
        >
          <div class="project-sum events-wrapper">
            <div class="current_sum">
              {{
                formatCurrency(
                  decimal(localItem.raisedAmount).plus(
                    localItem.pifReservedAmount,
                  ),
                  0,
                )
              }}
            </div>

            <div class="target_sum">
              {{ formatCurrency(localItem.loanAmount, 0) }}&nbsp;₽
            </div>
          </div>

          <div class="project-progress">
            <div
              :style="{
                width:
                  calculateProjectProgress(
                    decimal(localItem.raisedAmount).plus(
                      localItem.pifReservedAmount,
                    ),
                    localItem.loanAmount,
                  ) + '%',
              }"
              class="bar"
            ></div>
          </div>
        </template>

        <template v-else>
          <div class="project-sum events-wrapper">
            <div class="current_sum">Собрано полностью</div>
            <div class="target_sum">
              {{ formatCurrency(localItem.raisedAmount, 0) }}&nbsp;₽
            </div>
          </div>

          <div class="project-progress">
            <div
              :style="{
                width:
                  calculateProjectProgress(
                    decimal(localItem.raisedAmount).plus(
                      localItem.pifReservedAmount,
                    ),
                    localItem.loanAmount,
                  ) + '%',
              }"
              class="bar bar-green"
            ></div>
          </div>
        </template>

        <div class="project_data">
          <div v-if="localItem.irr" class="project_data_item">
            <VDropdown>
              <button class="info-icon-hint project_data_item_name">
                Эффективная ставка
              </button>
              <template #popper>
                <div class="popup">
                  <div v-close-popper class="popup-close"></div>
                  <div class="popup-text">
                    Ставка с учетом реинвестирования полученных доходов в
                    аналогичные займы
                  </div>
                </div>
              </template>
            </VDropdown>
            <div class="project_data_item_value">
              {{ formatCurrency(localItem.irr, 2) }}%
            </div>
          </div>
          <div class="project_data_item">
            <div class="project_data_item_name">Номинальная ставка</div>
            <div class="project_data_item_value">
              {{ formatCurrency(localItem.loanRate * 100, 2) }}%
            </div>
          </div>
          <div class="project_data_item">
            <div class="project_data_item_name">Срок</div>
            <div class="project_data_item_value">
              {{ localItem.loanTerm }}
              {{ pluralize(localItem.loanTerm, ['день', 'дня', 'дней']) }}
            </div>
          </div>

          <PotokDivider marginY="8" />

          <div
            v-if="localItem.borrowerHaveOtherInvestments"
            class="project_data_item"
          >
            <div v-if="loadingMainDebt" style="margin-right: 5px">
              <div class="load-icon"></div>
            </div>
            <template v-if="!loadingMainDebt">
              <div class="project_data_item_name">
                Остаток долга по компании
              </div>
              <div class="project_data_item_value">
                {{ formatCurrency(mainDebt, 0) }}&nbsp;₽
              </div>
            </template>
          </div>
          <div v-if="getInvestments().amount" class="project_data_item">
            <div class="project_data_item_name">Ваша текущая инвестиция</div>
            <div class="project_data_item_value">
              <router-link
                v-if="getInvestments().id"
                :to="{
                  name: 'investment',
                  params: {
                    id: getInvestments().id,
                  },
                }"
                target="_blank"
                >{{
                  formatCurrency(getInvestments().amount, 0)
                }}&nbsp;₽</router-link
              >
              <div v-else>
                {{ formatCurrency(getInvestments().amount, 0) }}&nbsp;₽
              </div>
            </div>
          </div>
          <div v-if="localItem.borrower.modelBucket" class="bucket-container">
            <div>Рейтинг:</div>
            <div class="bucket-items">
              <div
                v-for="(bucketItem, i) in bucketItems"
                :key="i"
                class="bucket-item"
                :class="{ 'bucket-item-colored': bucketItem.colored }"
              />
            </div>
          </div>
          <div
            v-if="localItem.borrower.totalMainDebtToInvestor && store.isAdmin"
            class="project_data_item"
          >
            <div class="project_data_item_name">Остаток ОД заёмщика:</div>
            <div class="project_data_item_value">
              {{
                formatCurrency(localItem.borrower.totalMainDebtToInvestor, 0)
              }}&nbsp;₽
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="investment-bottoms">
      <template
        v-if="!isFullRaised(localItem.loanAmount, localItem.raisedAmount)"
      >
        <button
          v-if="
            isUserLoaded && !isFullRaisedWithPif() && !getInvestments().amount
          "
          type="button"
          style=""
          class="button button-secondary-outline button_invest"
          @click="depositMoney()"
        >
          Инвестировать
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useStateStore } from '@/store/stateStore';
import { useRouter } from 'vue-router';

import LoansItemTags from './LoansItem/LoansItemTags.vue';

import {
  decimal,
  formatCurrency,
  pluralize,
  formatDate,
} from '@/utils/commonUtils/utils';

import server from '@/server';
import PifInvested from '@/components/_generic/tags/PifInvested.vue';

import tracker from '@/tracker';

const store = useStateStore();
const router = useRouter();

const props = defineProps(['item']);

const localItem = reactive(props.item);
const mainDebt = ref(0);
const loadingMainDebt = ref(false);

const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

watch(selectedCompanyId, async () => {
  getMainDebt();
});

const bucketItems = computed(() => {
  const bucketValue = localItem.borrower.modelBucket;

  const maxItems = bucketValue[bucketValue.length - 1];
  const coloredItems = bucketValue[0];

  const finalItems = Array.from({ length: maxItems }, (v, i) => {
    return {
      colored: i < coloredItems,
    };
  });

  return finalItems;
});

const thisInvestedPif = () => {
  return localItem.pifInvestments && localItem.loanType !== 'high_risk';
};
const clickAnalytic = () => {
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_My_Account_Loan_Preview_Click',
  );
  switch (localItem.loanType) {
    case 'main':
      tracker.queue(tracker.commands.SEND, 'Investor_LK_My_Account_Label_Main');
      break;
    case 'factoring':
      tracker.queue(
        tracker.commands.SEND,
        'Investor_LK_My_Account_Label_Factoring',
      );
      break;
    case 'tender':
      tracker.queue(
        tracker.commands.SEND,
        'Investor_LK_My_Account_Label_Tender',
      );
      break;
  }

  if (thisInvestedPif()) {
    tracker.queue(tracker.commands.SEND, 'Investor_LK_My_Account_Label_Pif');
  }
};

const isFullRaised = (loanAmount, raisedAmount) => {
  return decimal(raisedAmount).greaterThanOrEqualTo(loanAmount);
};

const isFullRaisedWithPif = () => {
  return (
    decimal(localItem.loanAmount).minus(decimal(localItem.raisedAmount)) <= 0
  );
};

const getInvestments = () => {
  let amount = 0;
  let isConfirmed = true;
  let id;
  for (const m of localItem.myInvestments) {
    if (
      selectedCompanyId.value &&
      m.company &&
      m.company.id &&
      m.company.id == selectedCompanyId.value
    ) {
      amount = m.amount;
      isConfirmed = m.isConfirmed;
      id = m.id;
      break;
    } else if (!selectedCompanyId.value) {
      amount = decimal(amount).plus(m.amount);
    }
  }
  return {
    amount,
    isConfirmed,
    id,
  };
};

const calculateProjectProgress = (_current, _target) => {
  const target = decimal(_target).toNumber() || 1;
  const value = decimal(_current).mul(100).div(target);
  return Math.floor(value);
};

const getMainDebt = () => {
  if (!props.item.borrowerHaveOtherInvestments || !selectedCompanyId.value) {
    return;
  }
  const query = {
    borrowerId: props.item.borrower.id,
  };
  let localMainDebt = 0;
  loadingMainDebt.value = true;
  server.getMainDebt
    .send(query, { params: { id: selectedCompanyId.value } })
    .pipe(
      (response) => {
        loadingMainDebt.value = false;
        if (response && response.data && response.data.mainDebt) {
          mainDebt.value = decimal(localMainDebt)
            .plus(response.data.mainDebt)
            .toNumber();
        }
      },
      () => {
        loadingMainDebt.value = false;
      },
    )
    .exec();
};

const depositMoney = () => {
  if (!selectedCompanyId.value) {
    return;
  }
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Manual_Preview');

  const route = {
    name: 'loanInvestment',
    params: {
      id: localItem.id,
    },
  };
  router.push(route);
};

onMounted(() => {
  getMainDebt();
});
</script>

<style lang="scss" scoped>
.bucket-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bucket-items {
  display: flex;
}
.bucket-item {
  width: 12px;
  height: 6px;
  margin-right: 3px;
  border: 1px solid grey;
}
.bucket-item-colored {
  background: grey;
}
.all-project-item {
  position: relative;
  display: block;
  height: 100%;
  padding: 16px 20px 20px 20px;

  &.content-block {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $size_767) {
      margin: 0 16px;
      padding: 16px 11px 20px 11px;
    }
  }

  .project-title {
    font-style: normal;
    display: block;
    min-height: 36px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: $deep_dive;
    margin-top: 16px;
    margin-bottom: 20px;
    text-transform: none;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: $size_767) {
      font-size: 14px;
      line-height: 16px;
      margin-top: -4px;
      margin-bottom: 18px;
    }
    div {
      word-break: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
    }
  }
  .project-sum {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    color: $deep_dive;
    display: flex;
    justify-content: space-between;
    @media (max-width: $size_767) {
      margin-bottom: 3px;
    }
  }
  .target_sum {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $grey;
  }
  .project-progress {
    margin-bottom: 11px;
    height: 10px;
    @media (max-width: $size_767) {
      margin-bottom: 15px;
    }
  }

  .project_details {
    margin-bottom: 20px;
  }
  .button-secondary-stroke {
    min-width: 132px;
    height: 28px;
    font-size: 11px;
    line-height: 28px;
    color: #0382ce;
    background: none;
    border: 1px solid #0382ce;

    &:disabled {
      color: $grey;
      border-color: $grey;
    }

    @media (max-width: 991px) and (min-width: 576px) {
      position: absolute;
      bottom: 20px;
      left: 16px;
    }
  }
}
.item_number_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.item_number {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey;
}
.project_data {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.project_data_item {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  @media (max-width: $size_767) {
    flex-direction: column;
    margin: 9px 0 18px 0;
  }
}
.project_data_item_name {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey;
  @media (max-width: $size_767) {
    margin-bottom: 3px;
  }
}
.project_data_item_value {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
}
.investment-bottoms {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 11px;
}
.button_invest {
  margin-top: 10px;
  width: 100%;
}
</style>
