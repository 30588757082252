<template>
  <div>
    <div class="dashboard-bonus-banner" @click="onClickMobile">
      <div class="dashboard-bonus-banner-content">
        <div class="dashboard-bonus-banner-text-container">
          <div class="dashboard-bonus-banner-text potok-text-body-1">
            специальный бонус для вас за первое пополнение
          </div>
          <div class="dashboard-bonus-banner-title mt-2 potok-text-h1">
            до + 14% годовых
          </div>
        </div>
        <button
          class="dashboard-bonus-banner-btn potok-text-body-1 no_mobile"
          @click="onClickBonusModal(true)"
        >
          подробные условия
        </button>
      </div>
    </div>
    <DashboardModalBonus
      v-if="isShowBonusModal"
      @close="onClickBonusModal(false)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import DashboardModalBonus from '@/components/Dashboard/modals/DashboardModalBonus.vue';
import detectDevice from '@/composables/detectDeviceComposable';

const { isMobile } = detectDevice();

const isShowBonusModal = ref(false);

const onClickBonusModal = (value) => {
  isShowBonusModal.value = value;
};

const onClickMobile = () => {
  if (isMobile) {
    onClickBonusModal(true);
  }
};
</script>

<style scoped lang="scss">
@import 'scss/typography.scss';

.dashboard-bonus-banner {
  width: 100%;
  padding: 25px 30px 25px 30px;
  background-color: $dividers;
  border-radius: 14px;
  background-image: url('@img/banners/dashboard-bonus-background.png');
  background-repeat: no-repeat;

  @media (max-width: $size_991) {
    padding: 20px 12px 20px 12px;
    background-image: url('@img/banners/dashboard-bonus-background-mobile.png');
  }
}
.dashboard-bonus-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 115px;

  @media (max-width: $size_991) {
    margin-left: 60px;
  }
}
.dashboard-bonus-banner-text-container {
  display: flex;
  flex-direction: column;
}
.dashboard-bonus-banner-title {
  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}
.dashboard-bonus-banner-text {
  color: $grey;
  @media (max-width: $size_991) {
    @include potok-text-body-2;
  }
}
.dashboard-bonus-banner-text-special {
  color: $tiffany;
}
.dashboard-bonus-banner-btn {
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $breakwater;
  color: $breakwater;
  background-color: transparent;
  padding: 10px 18px 10px 18px;
  &:hover {
    background-color: $breakwater;
    color: $white;
  }
}
</style>