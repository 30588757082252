<template>
  <div style="text-transform: none">
    <FormFieldTitle
      :divider="false"
      :big="true"
      :title="'состояние расчетов'"
      style="margin: 25px 0 10px 0"
    />
    <swiper :slides-per-view="'auto'" style="margin-right: -15px">
      <swiper-slide>
        <div style="margin-right: 16px">
          <div class="block_content">
            <FormFieldTitle :divider="false" :title="'основной долг'" />
            <FormField :divider="false">
              <template #name>
                <span v-if="props.item.status !== 'cession'"
                  >возвращено факт</span
                >
                <span v-if="props.item.status === 'cession'"
                  >возвращено заемщиком</span
                >
              </template>
              <template #value>
                <template v-if="props.item.status === 'cession'">
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refundedByBorrower
                        .mainDebt,
                      0,
                    )
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refunded.mainDebt,
                      0,
                    )
                  }}</span>
                </template>

                ₽
              </template>
            </FormField>
            <FormField>
              <template #name>
                <span v-if="props.item.status !== 'cession'">остаток план</span>
                <span v-if="props.item.status === 'cession'"
                  >возвращено по цессии — Поток</span
                >
              </template>
              <template #value>
                <template v-if="props.item.status === 'cession'">
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refundedByPlatform
                        .mainDebt,
                      0,
                    )
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.expecting.mainDebt,
                      0,
                    )
                  }}</span>
                </template>
                ₽
              </template>
            </FormField>
            <FormField>
              <template #name>
                <span v-if="props.item.status !== 'cession'">всего план</span>
                <span v-if="props.item.status === 'cession'">всего факт</span>
              </template>
              <template #value>
                <span>{{
                  formatCurrency(
                    props.item.stateOfCalculations.total.mainDebt,
                    0,
                  )
                }}</span>
                ₽
              </template>
            </FormField>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div style="margin-right: 16px">
          <div class="block_content">
            <FormFieldTitle :divider="false" :title="'проценты и пени'" />
            <FormField :divider="false">
              <template #name>
                <span v-if="props.item.status !== 'cession'"
                  >возвращено факт</span
                >
                <span v-if="props.item.status === 'cession'"
                  >возвращено заемщиком</span
                >
              </template>
              <template #value>
                <template v-if="props.item.status === 'cession'">
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refundedByBorrower.income,
                      0,
                    )
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refunded.income,
                      0,
                    )
                  }}</span>
                </template>
                ₽
              </template>
            </FormField>
            <FormField>
              <template #name>
                <span v-if="props.item.status !== 'cession'">остаток план</span>
                <span v-if="props.item.status === 'cession'"
                  >возвращено по цессии — Поток</span
                >
              </template>
              <template #value>
                <template v-if="props.item.status === 'cession'">
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refundedByPlatform.income,
                      0,
                    )
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.expecting.income,
                      0,
                    )
                  }}</span>
                </template>
                ₽
              </template>
            </FormField>
            <FormField>
              <template #name>
                <span v-if="props.item.status !== 'cession'">всего план</span>
                <span v-if="props.item.status === 'cession'">всего факт</span>
              </template>
              <template #value>
                <span>{{
                  formatCurrency(props.item.stateOfCalculations.total.income, 0)
                }}</span>
                ₽
              </template>
            </FormField>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div style="margin-right: 16px">
          <div class="block_content">
            <FormFieldTitle :divider="false" :title="'общий возврат'" />
            <FormField :divider="false">
              <template #name>
                <span v-if="props.item.status !== 'cession'"
                  >возвращено факт</span
                >
                <span v-if="props.item.status === 'cession'"
                  >возвращено заемщиком</span
                >
              </template>
              <template #value>
                <template v-if="props.item.status === 'cession'">
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refundedByBorrower
                        .totalAmount,
                      0,
                    )
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refunded.totalAmount,
                      0,
                    )
                  }}</span>
                </template>
                ₽
              </template>
            </FormField>
            <FormField>
              <template #name>
                <span v-if="props.item.status !== 'cession'">остаток план</span>
                <span v-if="props.item.status === 'cession'"
                  >возвращено по цессии — Поток</span
                >
              </template>
              <template #value>
                <template v-if="props.item.status === 'cession'">
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.refundedByPlatform
                        .totalAmount,
                      0,
                    )
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    formatCurrency(
                      props.item.stateOfCalculations.expecting.totalAmount,
                      0,
                    )
                  }}</span>
                </template>
                ₽
              </template>
            </FormField>
            <FormField>
              <template #name>
                <span v-if="props.item.status !== 'cession'">всего план</span>
                <span v-if="props.item.status === 'cession'">всего факт</span>
              </template>
              <template #value>
                <span>{{
                  formatCurrency(
                    props.item.stateOfCalculations.total.totalAmount,
                    0,
                  )
                }}</span>
                ₽
              </template>
            </FormField>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
import { Swiper } from '@/libs/swiper/swiper.js';
import { SwiperSlide } from '@/libs/swiper/swiper-slide.js';
import '@/libs/swiper/swiper.min.css';

import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';
import FormField from '@/components/_generic/forms/FormField.vue';
import { formatCurrency } from '@/utils/commonUtils/utils';

const props = defineProps(['item']);
</script>
<style lang="scss" scoped>
:deep(.swiper-slide) {
  width: 90%;
}
.block_content {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 6px;
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
}
</style>
