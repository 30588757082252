<template>
  <section class="investment page documents_wrapper">
    <div class="investment-wrapper">
      <div class="title">документы инвестиционной платформы Поток</div>
      <div class="documents">
        <div class="docs-row">
          <div class="row">
            <div class="col-sm-6">
              <div class="project-docs">
                <div class="doc-item doc-item-pdf">
                  <a
                    href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
                    class="doc-name"
                    target="_blank"
                    >правила инвестиционной платформы Поток</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="project-docs">
                <div class="doc-item doc-item-pdf">
                  <a
                    href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%20%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B/%D0%9E%D0%B1%D1%89%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0%20%D0%B7%D0%B0%D0%B9%D0%BC%D0%B0.pdf"
                    class="doc-name"
                    target="_blank"
                    >общие условия договора займа</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="project-docs">
                <div class="doc-item doc-item-pdf">
                  <a
                    href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%20%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%B8%D0%BD%D0%BA%D0%B0%D1%81%D1%81%D0%BE-%D1%86%D0%B5%D1%81%D1%81%D0%B8%D0%B8.pdf"
                    class="doc-name"
                    target="_blank"
                    >договор инкассо-цессии</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="project-docs">
                <div class="doc-item doc-item-pdf">
                  <a
                    href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%20%D1%80%D0%B0%D1%81%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D0%B5%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B%20%D1%81%20%D0%BF%D1%80%D0%BE%D1%81%D1%80%D0%BE%D1%87%D0%B5%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%B7%D0%B0%D0%B4%D0%BE%D0%BB%D0%B5%D0%B6%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D1%8C%D1%8E.pdf"
                    class="doc-name"
                    target="_blank"
                    >политика работы с просроченной задолженностью</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="project-docs">
                <div class="doc-item doc-item-pdf">
                  <a
                    href="https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%BB%D0%BA%D0%B8/%D0%A1%D1%82%D1%80%D0%B0%D1%82%D0%B5%D0%B3%D0%B8%D0%B8%20%D0%B8%20%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D1%8B%20%D0%BE%D0%BF%D1%80%D0%B5%D0%B4%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D1%81%D1%83%D0%BC%D0%BC%D1%8B%20%D0%B8%D0%BD%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_%D0%B0%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5.pdf"
                    class="doc-name"
                    target="_blank"
                    >стратегии и параметры определения суммы инвестирования</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="project-docs">
                <div class="doc-item doc-item-pdf">
                  <a
                    href="https://files.potok.digital/Документы/раздел правила платформы/Politika_obrabotka_pers_dannyh_aktualnaja_redakcija.docx"
                    class="doc-name"
                    target="_blank"
                    >политика обработки персональных данных</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>
<style lang="scss" scoped>
.documents_wrapper {
  text-transform: none;
}
.investment {
  box-shadow: none;
  border-radius: 0;
  background: none;
}
</style>
