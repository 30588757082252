<template>
  <section class="page">
    <template v-if="!isUserLoaded">
      <Loader />
    </template>

    <template v-if="isUserLoaded">
      <notification-message />
      <NeedJoinEula v-if="needJoinEula" />
      <RejectedPassport
        v-if="isShowRejectedPassportModal"
        @close="isShowRejectedPassportModal = false"
      />
      <UserMessage
        class="mb-4"
        @open-rejected-passport="isShowRejectedPassportModal = true"
      />

      <div class="loans-header">
        <template v-if="store.isAdmin">
          <ul class="loans-header-list">
            <li class="loans-header-list-item">
              <PotokButton
                class="loans-header-item-link potok-text-h1"
                :class="{
                  'loans-header-item-active':
                    selectedPage === 'LoansFirstPage',
                }"
                theme="link"
                size="link"
                text="первичный рынок"
                @click="selectedPage = 'LoansFirstPage'"
              />
            </li>
            <li class="loans-header-list-item">
              <PotokButton
                class="loans-header-item-link potok-text-h1"
                :class="{
                  'loans-header-item-active':
                    selectedPage === 'LoansSecondPage',
                }"
                theme="link"
                size="link"
                text="вторичный рынок"
                @click="selectedPage = 'LoansSecondPage'"
              />
            </li>
          </ul>
        </template>
        <template v-if="!store.isAdmin">
          <h2 class="loans-header-title">новые займы</h2>
        </template>
        <div class="loans-header-available">
          <div class="loans-header-available-name">доступно на счете</div>
          <div class="loans-header-available-value">
            <span>{{ formatCurrency(getFreeBalance + getCashboxRealAmount, 0) }}</span>
            &nbsp;₽
          </div>
        </div>
      </div>

      <component :is="selectedPageComponent" />
    </template>
  </section>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';

import { Loader, PotokButton } from 'potok-uikit';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage.vue';
import NeedJoinEula from '@/components/_generic/messages/NeedJoinEula.vue';
import RejectedPassport from '@/components/_modals/RejectedPassport.vue';
import UserMessage from '@/components/_generic/messages/UserMessage.vue';
import tracker from '@/tracker';
import { formatCurrency } from '@/utils/commonUtils/utils';
import LoansFirstPage from '@/pages/Loans/LoansFirstPage.vue';
import LoansSecondPage from '@/pages/Loans/LoansSecondPage.vue';

const store = useStateStore();

const isShowRejectedPassportModal = ref(false);

const selectedPage = ref('LoansFirstPage')

const selectedPageComponent = computed(() => {
  if (selectedPage.value === 'LoansFirstPage') {
    return LoansFirstPage;
  }
  if (selectedPage.value === 'LoansSecondPage') {
    return LoansSecondPage;
  }
});

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});

const needJoinEula = computed(() => {
  if (
    store.selectedCompany &&
    store.selectedCompany.typeId !== constants.companyTypes.person
  ) {
    return (
      store.selectedCompany.isEulaApproved &&
      !store.selectedCompany.isAgreeRules
    );
  }
  return false;
});

const getFreeBalance = computed(() => {
  return store.getFreeBalance;
});
const getCashboxRealAmount = computed(() => {
  return store.getCashboxRealAmount;
});

watch(
  () => {
    return store.selectedCompany?.id;
  },
  () => {
    store.fetchActiveBalance({
      companyId: store.selectedCompany?.id,
    });
  },
);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_New_Projects');
  store.fetchActiveBalance({
    companyId: store.selectedCompany?.id,
  });
});
</script>
<style lang="scss" scoped>
.loans-header {
  display: flex;
  justify-content: space-between;
  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.loans-header-available {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: rgba(23, 59, 87, 0.06);
  border-radius: 8px;
  padding: 8px 20px 14px 20px;
  border-right: 3px solid #173b57;
  @media (max-width: $size_767) {
    align-items: center;
    margin-top: 17px;
    padding: 3px 20px 10px 20px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-right: initial;
    border-left: 3px solid #173b57;
  }
}
.loans-header-available-name {
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
  margin-top: 6px;
}
.loans-header-available-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  margin-top: 5px;
}
.loans-header-list {
  text-transform: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.loans-header-list-item {
  margin-right: 20px;

  @media (max-width: $size_991) {
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}
.loans-header-item-link {
  text-decoration: none;
  color: $grey;

  &:hover {
    color: $deep_dive;
  }

  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}
.loans-header .loans-header-item-active {
  position: relative;
  color: $deep_dive;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -8px;
    left: 0;
    height: 2px;
    background-color: $deep_dive;
  }
}
</style>
