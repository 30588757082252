<template>
  <section style="margin-top: 30px">
    <FormTitle :title="'Завершите регистрацию'" />
    <template v-if="loading">
      <div class="icon-loading" style="margin-top: 20px" />
    </template>
    <template v-if="!loading && currentStep">
      <PotokMessage
        v-if="hasGosUslugiError && isShowGosUslugiError"
        class="error-gosuslugi"
      >
        <PotokButton
          theme="linkIcon"
          size="link"
          class="message-close-icon"
          @click="isShowGosUslugiError = false"
        >
          <template #icon>
            <PotokImg
              :src="imageConstants.closeGreyRounded"
              height="14"
              width="14"
            />
          </template>
        </PotokButton>
        <div>
          {{ gosUslugiErrorMessage }}
        </div>
      </PotokMessage>
      <OnboardDesktop
        v-if="!isMobile"
        :current-step="currentStep"
        :profile-data="data.profileData"
        :steps="steps"
        @update-step="updateStep"
      />
      <OnboardMobile
        v-if="isMobile"
        :current-step="currentStep"
        :profile-data="data.profileData"
        :steps="steps"
        @update-step="updateStep"
      />
    </template>
  </section>
</template>
<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
} from 'vue';
import { useStateStore } from '@/store/stateStore';
import imageConstants from '@/constants/imageConstants';
import server from '@/server';
import detectDevice from '@/composables/detectDeviceComposable';
import FormTitle from '@/components/_generic/forms/FormTitle.vue';
import PotokMessage from '@/components/_generic/PotokMessage.vue';
import OnboardDesktop from '../../components/Dashboard/DashboardOnboard/DashboardOnboardContainers/DashboardOnboardDesktop.vue';
import OnboardMobile from '../../components/Dashboard/DashboardOnboard/DashboardOnboardContainers/DashboardOnboardMobile.vue';
import { useRoute } from 'vue-router';
import { PotokButton } from 'potok-uikit';

const emits = defineEmits(['showConfirmMessage']);
const store = useStateStore();
const route = useRoute();

const { isMobile } = detectDevice();

const loading = ref(true);
const currentStep = ref('passport');
const data = reactive({
  profileData: null,
});

const intervalId = ref(null);

const steps = ref([]);

const isShowGosUslugiError = ref(true);

const gosUslugiErrorMessage = computed(() => {
  return route.query.message;
});
const hasGosUslugiError = computed(() => {
  return route.query.publicService && route.query.error && route.query.message;
});

const updateStep = (obj) => {
  data.profileData = null;
  if (obj.step) {
    currentStep.value = detectCurrentStep(obj.step);
  }
  if (obj.profileData) {
    data.profileData = obj.profileData;
  }
};
const detectCurrentStep = (type) => {
  const currentStepValue = type;
  if (currentStepValue === 'recognize') {
    setTimeout(() => {
      getOnboardStatus();
    }, 5000);
  }
  if (type === 'finished') {
    getUsersMeAsync();
    getCompaniesAsync();
    emits('showConfirmMessage');
  }
  return currentStepValue;
};
const getOnboardStatus = () => {
  if (currentStep.value !== 'recognize') {
    loading.value = true;
  }
  return server.getUserOnboard
    .send()
    .pipe(onFetchOnboardStatusSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onFetchOnboardStatusSuccess = (obj) => {
  loading.value = false;
  data.profileData = null;
  currentStep.value = detectCurrentStep(obj.data.onboardingStatus);
  store.setUserOnboardingStatus(obj.data.onboardingStatus);
  steps.value = obj.data.steps;

  if (currentStep.value === 'profile' && obj.data.data) {
    data.profileData = obj.data.data;
  }
};
const getUsersMeAsync = () => {
  return server.getUsersMe.send().pipe(onGetUsersMeAsyncSuccess).exec();
};
const onGetUsersMeAsyncSuccess = ({ data: user }) => {
  store.merge({ user });
};

const getNotificationsAsync = () => {
  const query = {
    isGlobal: false,
  };
  return server.getNotification
    .send(query)
    .pipe(onGetNotificationAsyncSuccess)
    .exec();
};

const onGetNotificationAsyncSuccess = ({ data }) => {
  store.pushMenuNotifications(data);
  const counter = data.filter((i) => !i.isViewed).length;
  store.merge({
    notification: {
      counter,
    },
  });
};

const getCompaniesAsync = () => {
  store.fetchCompanies();
};

onMounted(() => {
  getOnboardStatus();
});

watch(
  currentStep,
  () => {
    if (currentStep.value === 'strategy' || currentStep.value === 'balance') {
      if (!intervalId.value) {
        getNotificationsAsync();
        intervalId.value = setInterval(() => {
          getNotificationsAsync();
        }, 5000);
      }
    }
  },
  { immediate: true },
);

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
});
</script>
<style lang="scss" scoped>
.сonfirm_message__title {
  color: #173b57;
  margin-top: -20px;
  @media (max-width: $size_767) {
    margin-top: initial;
  }
}

:deep(.step_title) {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: $deep_dive;
  margin-bottom: 12px;
  @media (max-width: $size_767) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
:deep(.step_description) {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey;
  padding-right: 100px;
  @media (max-width: $size_767) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: $size_991) {
    padding-right: 0;
  }
}

:deep(.img-inputer) {
  .img-inputer__icon {
    color: #93a1b0 !important;
    top: 40% !important;
  }
}
.error-gosuslugi {
  position: relative;
}
.message-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
  width: auto;
}
</style>
