<template>
  <div class="custom-checkbox-row" @click="changeValue">
    <div>
      <input
        :checked="props.modelValue"
        type="checkbox"
        class="custom-checkbox-hidden"
      />
      <label :class="[{ disabled: props.disabled }]">
        <span class="check"></span>
        <span
          v-if="props.title"
          :class="{
            label_text: !props.smallText,
            label_text_small: props.smallText,
          }"
          >{{ props.title }}</span
        >
      </label>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: () => false,
  },
  title: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  smallText: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'makeDirty', 'change']);

const changeValue = (evt) => {
  evt.preventDefault();

  if (props.disabled) return;

  emit('update:modelValue', !props.modelValue);
};
</script>

<style lang="scss">
.custom-checkbox-row {
  color: #717d83;

  .custom-checkbox-hidden {
    display: none;
    &:checked ~ label {
      .check {
        border: none;
        background-image: url('@img/icons/checkbox/check-recatangle.svg');
        background-repeat: no-repeat;
        background-position: center center;
        &:hover {
          background-image: url('@img/icons/checkbox/check-recatangle-hover.svg');
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  label.disabled {
    .check {
      background-color: #b5b5b5;
      border-color: #b5b5b5;
    }
  }
  .check {
    display: inline-block;
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-right: 12px;
    border-radius: 2px;
    vertical-align: middle;
    background-color: $background;
  }
  .label_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: $deep_dive;
  }
  .label_text_small {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey;
  }
}
</style>
