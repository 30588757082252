<template>
  <div class="form_input">
    <label class="form_input__label mb-0" :for="`input-${uuid}`">
      <div
        v-if="value"
        class="form_input__title"
        :class="{
          'potok-color-red': isFormErrorClass,
        }"
      >
        {{ props.title }}

        <slot name="label"></slot>
      </div>

      <div class="form_input__container">
        <input
          :id="`input-${uuid}`"
          v-model="value"
          v-maska="props.vmaska"
          class="form_input__input"
          type="text"
          :disabled="props.disabled"
          :placeholder="props.title"
          @input="inputValue"
        />

        <slot name="inner-icon"></slot>
      </div>
    </label>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { uuidv4 } from '@/utils/commonUtils/utils';

const props = defineProps({
  modelValue: [String, Number],
  vmaska: [Object, String],
  disabled: Boolean,
  title: String,
  validate: Object,
  input: Function,
});
const emit = defineEmits(['update:modelValue', 'inputValue']);

const uuid = uuidv4();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const isFormErrorClass = computed(() => {
  return props.validate?.$invalid && props.validate?.$dirty;
});

const inputValue = () => {
  props.validate ? props.validate.$touch() : null;
  emit('inputValue');
};
</script>
<style lang="scss" scoped>
label {
  width: 100%;
}
.form_input {
  position: relative;
}
.form_input__container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #dce0e3;
  border-radius: 8px;
}
.form_input__title {
  position: absolute;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
  top: -5px;
  left: 14px;
  background-color: white;
  padding: 0 5px;
  display: flex;
  align-items: center;
}
.form_input__input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  display: block;
  width: 100%;
  height: 44px;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: #fff;
  border: 0 solid;
  &:disabled {
    color: $grey;
  }
  &::placeholder {
    color: $grey;
  }
}

.form_input__input__error {
  width: 300px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $red;
}
</style>
