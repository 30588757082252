<template>
  <div v-if="!loading">
    <div class="dashboard-exclusive-loans-banner">
      <div class="dashboard-exclusive-loans-banner-content">
        <div class="dashboard-exclusive-loans-banner-text-container">
          <div
            class="dashboard-exclusive-loans-banner-title potok-text-h2"
            v-html="texts.cardTitle"
          />
          <div
            class="dashboard-exclusive-loans-banner-text mt-2 potok-text-body-1"
          >
            {{ texts.cardDescription }}
          </div>
        </div>
        <PotokButton
          :text="texts.investButtonLabel"
          theme="linkIconUpload"
          size="link"
          noFullWidthMobile
          class="dashboard-exclusive-loans-banner-btn"
          @click="toLink"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { PotokButton } from 'potok-uikit';
import server from '@/server';

const loading = ref(true);
const texts = ref({
  cardTitle: '',
  cardDescription: '',
  investButtonLabel: '',
  investLink: '',
});

const toLink = () => {
  window.open(texts.value.investLink, '_blank');
};

const getExclusivePotokLoansBanner = () => {
  server.getExclusivePotokLoansBanner
    .send()
    .pipe((data) => {
      texts.value = data.data;
      loading.value = false;
    }, () => loading.value = false)
    .exec();
}

const init = () => {
  getExclusivePotokLoansBanner();
};
init();
</script>

<style scoped lang="scss">
@import 'scss/typography.scss';

.dashboard-exclusive-loans-banner {
  width: 100%;
  padding: 25px 30px 25px 30px;
  background-color: $dividers;
  border-radius: 14px;
  background-image: url('@img/banners/exclusiveBanner.png');
  background-repeat: no-repeat;

  @media (max-width: $size_991) {
    padding: 16px 24px 20px 20px;
    background-position: 110% 150%;
  }
}
.dashboard-exclusive-loans-banner-content {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-left: 135px;

  @media (max-width: $size_991) {
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.dashboard-exclusive-loans-banner-text-container {
  display: flex;
  flex-direction: column;
}
.dashboard-exclusive-loans-banner-title {
  @media (max-width: $size_991) {
    @include potok-text-body-2;
    font-weight: 500;
    letter-spacing: -0.01em;
  }
}
.dashboard-exclusive-loans-banner-text {
  color: $grey;
  @media (max-width: $size_991) {
    @include potok-text-body-2;
  }
}
.dashboard-exclusive-loans-banner-text-special {
  color: $tiffany;
}
.dashboard-exclusive-loans-banner-btn {
  flex-shrink: 0;
  color: #10273D;
  margin-left: 10px;
  @media (max-width: $size_991) {
    margin-left: 0;
    margin-top: 20px;
  }
}
</style>