<template>
  <section class="investments-sell-list">
    <NewInvestmentsSellListTitle
      :is-second-market="store.isSecondaryMarketOperator"
      :has-data="allListCount"
      :size="currentPoolSize"
      :limits="currentPoolSizeWithLimits"
      :is-loading="isLoadingTitle"
    />

    <NewInvestmentsSellListTableDesktop
      v-if="!isTablet && !userHasNoSells"
      :items="sellListDesktop"
      :is-loading="isLoading"
      :items-count="listCountDesktop"
      :items-active-count="listActiveCountDesktop"
      @fetch-list="getInvestmentsSellListDesktop"
    />
    <NewInvestmentsSellListTableMobile
      v-if="isTablet && !userHasNoSells"
      :items="sellListMobile"
      :is-loading="isLoading"
      :is-loading-more="isLoadingMobileMore"
      :items-count="listCountMobile"
      @change-status="getInvestmentsSellListMobile"
      @load-more-list="getInvestmentsSellListMobileMore"
      @toggle-item="toggleMobileItem"
    />

    <ModalFull
      v-if="isShowNewInvestmentsModal"
      title="заявка на продажу инвестиций создана"
      class="new-investments-success-modal"
      @close="closeSellInvestmentsModal"
    >
      <template #content>
        <div class="sell-investments-success-modal-text">
          Мы рассмотрим ее в течение 7 рабочих дней. В случае акцепта заявки
          деньги скоро поступят на ваш счет. Следить за статусом заявки и
          инвестиций в ней можно в разделе заявок на продажу.
        </div>
      </template>
      <template #actions>
        <PotokButton
          :text="'понятно'"
          class="sell-investments-success-modal-btn"
          @click="closeSellInvestmentsModal"
        />
      </template>
    </ModalFull>
  </section>
</template>

<script setup>
import NewInvestmentsSellListTitle from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListTitle.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { useStateStore } from '@/store/stateStore';
import { computed, ref, watch } from 'vue';
import server from '@/server';
import detectDevice from '@/composables/detectDeviceComposable';
import NewInvestmentsSellListTableDesktop from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListTable/NewInvestmentsSellListTableDesktop.vue';
import NewInvestmentsSellListTableMobile from '@/components/Investments/NewInvestmentsSellList/NewInvestmentsSellListTable/NewInvestmentsSellListTableMobile.vue';
import { PotokButton } from 'potok-uikit';

const { isTablet } = detectDevice();

const store = useStateStore();

const isLoading = ref(true);
const isLoadingMobileMore = ref(false);
const isLoadingTitle = ref(true);

const sellListDesktop = ref([]);
const sellListMobile = ref([]);
const listCountDesktop = ref(0);
const listActiveCountDesktop = ref(0);
const listCountMobile = ref(0);
const allListCount = ref(0);

const currentPoolSize = ref(0);
const currentPoolSizeWithLimits = ref(0);

const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const userHasNoSells = computed(() => {
  return !allListCount.value && !isLoading.value;
});

const isShowNewInvestmentsModal = computed(() => {
  return store.state.modals.isShowNewSaleInvestmentsModal;
});

const closeSellInvestmentsModal = () => {
  store.state.modals.isShowNewSaleInvestmentsModal = false;
};

const modifyMobileItems = (items) => {
  return items.map((el) => {
    return {
      ...el,
      isActiveItem: false,
    };
  });
};

const toggleMobileItem = (id) => {
  sellListMobile.value.forEach((el) => {
    // Если мы нажимаем на уже открытый айтем, то просто закрываем его
    if (el.isActiveItem && el.id === id) {
      el.isActiveItem = false;
      return;
    }

    el.isActiveItem = el.id === id;
  });
};

const getInvestmentsSellListDesktop = (obj) => {
  isLoading.value = true;

  const query = {
    sort: 'number',
    order: 'DESC',
    limit: obj.selectedPageRange,
    offset: obj.currentPage * obj.selectedPageRange - obj.selectedPageRange,
  };

  if (obj.status !== 'all') {
    query.status = obj.status;
  }

  if (store.isSecondaryMarketOperator) {
    return server.getInvestmentsSellList
      .send(query)
      .pipe(onFetchInvestmentsSellListDesktopSuccess, () => {
        isLoading.value = false;
      })
      .exec();
  }

  query.companyId = store.selectedCompanyId;

  return server.getInvestmentsSellListNotAdmin
    .send(query)
    .pipe(onFetchInvestmentsSellListDesktopSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};
const onFetchInvestmentsSellListDesktopSuccess = (obj) => {
  isLoading.value = false;
  listCountDesktop.value = obj.data.count;
  listActiveCountDesktop.value = obj.data.confirmedCount;
  sellListDesktop.value = obj.data.rows;
};

const getInvestmentsSellListMobile = (obj) => {
  isLoading.value = true;

  const query = {
    sort: 'number',
    order: 'DESC',
    limit: 20,
    offset: obj.currentPage * obj.selectedPageRange - obj.selectedPageRange,
  };

  if (obj.status !== 'all') {
    query.status = obj.status;
  }

  if (store.isSecondaryMarketOperator) {
    return server.getInvestmentsSellList
      .send(query)
      .pipe(onFetchInvestmentsSellListMobileSuccess, () => {
        isLoading.value = false;
      })
      .exec();
  }

  query.companyId = store.selectedCompanyId;

  return server.getInvestmentsSellListNotAdmin
    .send(query)
    .pipe(onFetchInvestmentsSellListMobileSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};
const onFetchInvestmentsSellListMobileSuccess = (obj) => {
  isLoading.value = false;
  listCountMobile.value = obj.data.count;
  sellListMobile.value = modifyMobileItems(obj.data.rows);
};

const getInvestmentsSellListMobileMore = (obj) => {
  isLoadingMobileMore.value = true;

  const query = {
    sort: 'number',
    order: 'DESC',
    limit: 20,
    offset: obj.currentPage * obj.selectedPageRange - obj.selectedPageRange,
  };

  if (obj.status !== 'all') {
    query.status = obj.status;
  }

  if (store.isSecondaryMarketOperator) {
    return server.getInvestmentsSellList
      .send(query)
      .pipe(onFetchInvestmentsSellListMobileMoreSuccess, () => {
        isLoading.value = false;
      })
      .exec();
  }

  query.companyId = store.selectedCompanyId;

  return server.getInvestmentsSellListNotAdmin
    .send(query)
    .pipe(onFetchInvestmentsSellListMobileMoreSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};
const onFetchInvestmentsSellListMobileMoreSuccess = (obj) => {
  isLoadingMobileMore.value = false;
  listCountMobile.value = obj.data.count;

  const modifyMobileItemsArray = modifyMobileItems(obj.data.rows);
  modifyMobileItemsArray.forEach((el) => {
    sellListMobile.value.push(el);
  });
};

const getInvestmentsSellListInit = () => {
  isLoading.value = true;

  const query = {
    sort: 'number',
    order: 'DESC',
    limit: 20,
    offset: 0,
  };

  if (store.isSecondaryMarketOperator) {
    return server.getInvestmentsSellList
      .send(query)
      .pipe(onFetchInvestmentsSellListInitSuccess, () => {
        isLoading.value = false;
      })
      .exec();
  }

  query.companyId = store.selectedCompanyId;

  return server.getInvestmentsSellListNotAdmin
    .send(query)
    .pipe(onFetchInvestmentsSellListInitSuccess, () => {
      isLoading.value = false;
      isLoadingTitle.value = false;
    })
    .exec();
};
const onFetchInvestmentsSellListInitSuccess = (obj) => {
  isLoading.value = false;
  listCountDesktop.value = obj.data.count;
  listActiveCountDesktop.value = obj.data.confirmedCount;
  listCountMobile.value = obj.data.count;
  allListCount.value = obj.data.count;
  sellListDesktop.value = obj.data.rows;
  sellListMobile.value = modifyMobileItems(obj.data.rows);
  if (!store.isSecondaryMarketOperator) {
    isLoadingTitle.value = false;
  }
};

const getInvestmentsAmountTotal = () => {
  isLoadingTitle.value = true;

  return server.getInvestmentsAmountTotal
    .send()
    .pipe(onFetchInvestmentsAmountTotalSuccess, () => {
      isLoadingTitle.value = false;
    })
    .exec();
};
const onFetchInvestmentsAmountTotalSuccess = (obj) => {
  isLoadingTitle.value = false;
  currentPoolSize.value = obj.data.mainDebtAmountTotal;
  currentPoolSizeWithLimits.value = obj.data.mainDebtWithLimitAmountTotal;
};

watch(selectedCompany, () => {
  if (store.isSecondaryMarketOperator) {
    getInvestmentsAmountTotal();
  }
  getInvestmentsSellListInit();
});

const init = () => {
  if (store.isSecondaryMarketOperator) {
    getInvestmentsAmountTotal();
  }
  getInvestmentsSellListInit();
};
init();
</script>

<style lang="scss" scoped>
.new-investments-success-modal {
  :deep(.modal_block_title) {
    justify-content: center;
    .modal_title {
      text-align: center;
      width: 276px;
    }
  }
  :deep(.modal_block_container) {
    max-width: 443px;
    @media (max-width: $size_767) {
      max-width: inherit;
    }
  }
  .sell-investments-success-modal-text {
    text-transform: initial;
    text-align: center;
    @media (max-width: $size_767) {
      text-align: initial;
    }
  }
  .sell-investments-success-modal-btn {
    margin: 0 auto;
  }
}
</style>
