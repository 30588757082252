<template>
  <div class="field-container">
    <PotokDivider
      v-if="props.divider"
      class="settings-field-divider"
    />
    <div
      class="field"
      :class="{ 'on-hover': props.clickableMode }"
      @click.prevent="handleClick"
    >
      <div class="item-info">
        <div class="item-name potok-text-uppercase">
          {{ props.name }}
        </div>
        <div
          class="item-value mt-1"
          :class="[props.fieldError ? 'item-error' : '']"
        >
          <template v-if="props.value">
            <div v-html="props.value" />
          </template>
          <template v-else>
            <slot name="value" />
          </template>
        </div>
      </div>
      <PotokImg
        v-if="props.clickableMode && !isMobile"
        height="20"
        width="20"
        class="on-hover-only"
        :src="imageConstants.pencil"
      />
      <PotokImg
        v-if="props.clickableMode && isMobile"
        height="7"
        width="16"
        :src="imageConstants.arrowRightDeepdiveLong"
      />
    </div>
  </div>
</template>
<script setup>
import imageConstants from "@/constants/imageConstants";
import detectDevice from "@/composables/detectDeviceComposable";

const { isMobile } = detectDevice()

const props = defineProps({
  name: {
    type: String,
  },
  value: {
    type: String,
  },
  divider: {
    type: Boolean,
    default: true,
  },
  clickableMode: {
    type: Boolean,
    default: false,
  },
  fieldError: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits('fieldClick')

const handleClick = () => {
  if (props.clickableMode) {
    emits('fieldClick');
  }
}
</script>
<style lang="scss" scoped>
.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  @media (max-width: $size_767) {
    padding: 16px 0;
  }
}
.item-name {
  color: $grey;
  padding-right: 0;
  display: flex;
  align-items: center;
  @media (max-width: $size_767) {
    color: $grey-fields;
    padding-bottom: 5px;
  }
  p {
    margin: 0;
  }
}

.item-value {
  display: flex;
  color: $deep_dive;
  word-break: break-word;
  text-transform: none;
  @media (max-width: $size_767) {
  }
}
.field-container .settings-field-divider {
  width: calc(100% - 40px);
  margin: 0 auto;

  @media (max-width: $size_767) {
    width: calc(100% - 32px);
  }
}
.on-hover {
  &:hover {
    border-radius: 14px;
    background: $background;
    cursor: pointer;
    .on-hover-only {
      display: block;
    }
  }
}
.on-hover-only {
  display: none;
}
.item-error {
  color: $error;
}
</style>
