<template>
  <div>
    <div class="block_content" style="margin-top: 10px">
      <div class="block_title">
        История событий по моим займам
        <VDropdown placement="bottom-start">
          <button class="info-icon">
            <i class="icon_question"></i>
          </button>
          <template #popper>
            <div class="popup">
              <div v-close-popper class="popup-close"></div>
              <div class="popup-text">
                В жизненном цикле займа может быть несколько событий: выдача,
                возврат, досрочное погашение и др.
              </div>
            </div>
          </template>
        </VDropdown>
      </div>

      <div style="margin-top: 20px">
        <input
          v-model="filter.searchString"
          placeholder="поиск по номеру проекта, ИНН, названию"
          class="analitics-find-number form-control search_input"
          type="text"
          @input="findBySearchString"
        />
      </div>
    </div>

    <div style="margin-top: 20px">
      <div>
        <div v-for="(item, index) in items" :key="index" class="item">
          <div @click="goToProject(item)">
            <component
              :is="componentNames[item.typeId]"
              :item="item"
            ></component>
          </div>
        </div>
      </div>

      <VueEternalLoading
        v-if="inited && selectedCompanyId"
        :load="loadProjectsLogPublicAsync"
      >
        <template #loading>
          <div class="analytics-summary content-block">
            <div class="row">
              <div class="col-md-4 col-xs-8">
                <Loader />
              </div>
            </div>
          </div>
        </template>

        <template #no-more>
          <div></div>
        </template>

        <template #no-results>
          <div class="projects-wrapper projects-row">
            <div class="project-item no_history">
              <i class="icon_circle_cross"></i>
              У вас пока нет истории
            </div>
          </div>
        </template>

        <template #error>
          <div class="projects-wrapper projects-row">
            <div class="project-item" style="padding-left: 50px">
              Ошибка получения истории
            </div>
          </div>
        </template>
      </VueEternalLoading>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, reactive, watch, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import { VueEternalLoading } from '@ts-pro/vue-eternal-loading';
import Pagination from '@/utils/classes/Pagination.js';

import { Loader } from 'potok-uikit';
import ProjectAcceptance from '../../components/Analytics/AnalyticsHistoryEvent/ProjectAcceptance.vue';
import ProjectCancel from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCancel.vue';
import ProjectOverdue from '../../components/Analytics/AnalyticsHistoryEvent/ProjectOverdue.vue';
import ProjectPartialFinalPayoff from '../../components/Analytics/AnalyticsHistoryEvent/ProjectPartialFinalPayoff.vue';
import ProjectPartialPayoff from '../../components/Analytics/AnalyticsHistoryEvent/ProjectPartialPayoff.vue';
import ProjectPayoff from '../../components/Analytics/AnalyticsHistoryEvent/ProjectPayoff.vue';
import ProjectCession1st from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCession1st.vue';
import ProjectCession2nd from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCession2nd.vue';
import ProjectCessionFixed from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCessionFixed.vue';
import ProjectCessionPartialPayoff from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCessionPartialPayoff.vue';
import ProjectCessionFinalPayoff from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCessionFinalPayoff.vue';
import ProjectForceOverdue from '../../components/Analytics/AnalyticsHistoryEvent/ProjectForceOverdue.vue';
import ProjectCameOutOverdue from '../../components/Analytics/AnalyticsHistoryEvent/ProjectCameOutOverdue.vue';
import ProjectStageOverdue from '../../components/Analytics/AnalyticsHistoryEvent/ProjectStageOverdue.vue';
import constants from '@/constants';
import server from '@/server';

import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const router = useRouter();

const loadingProjectsLogPublic = ref(false);
const pagination = new Pagination(20);
const items = ref([]);
const filter = reactive({
  searchString: '',
});
const inited = ref(false);

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const componentNames = computed(() => {
  const { eventLogTypes } = constants;
  return {
    [eventLogTypes.projectAcceptance]: ProjectAcceptance,
    [eventLogTypes.projectCancel]: ProjectCancel,
    [eventLogTypes.projectOverdue]: ProjectOverdue,
    [eventLogTypes.projectPayoff]: ProjectPayoff,
    [eventLogTypes.projectPartialPayoff]: ProjectPartialPayoff,
    [eventLogTypes.projectPartialFinalPayoff]: ProjectPartialFinalPayoff,
    [eventLogTypes.projectCession1st]: ProjectCession1st,
    [eventLogTypes.projectCession2nd]: ProjectCession2nd,
    [eventLogTypes.projectCessionFixed]: ProjectCessionFixed,
    [eventLogTypes.projectCessionPartialPayoff]: ProjectCessionPartialPayoff,
    [eventLogTypes.projectCessionFinalPayoff]: ProjectCessionFinalPayoff,
    [eventLogTypes.projectForceOverdue]: ProjectForceOverdue,
    [eventLogTypes.projectCameOutOverdue]: ProjectCameOutOverdue,
    [eventLogTypes.projectStageOverdue]: ProjectStageOverdue,
    [eventLogTypes.projectPartialPayment]: ProjectPartialPayoff,
    [eventLogTypes.projectPartialPaymentWithOverdue]: ProjectPartialPayoff,
    [eventLogTypes.projectAllPaymentWithOverdue]: ProjectPartialFinalPayoff,
  };
});

watch(selectedCompanyId, () => {
  inited.value = false;
  reset();
  nextTick(() => {
    inited.value = true;
  });
});

onMounted(() => {
  reset();
  inited.value = true;
});

const getProjectsLogPublicAsync = (action) => {
  if (!selectedCompanyId.value || !selectedCompanyId.value) {
    return;
  }
  let loaded;
  if (action) {
    loaded = action.loaded;
  }
  const { chunk: limit, offset } = pagination;
  const query = {
    offset,
    limit,
    searchString: filter.searchString,
    companyId: selectedCompanyId.value,
  };
  loadingProjectsLogPublic.value = true;
  return server.getProjectsLogPublic
    .send(query)
    .pipe(
      onGetProjectsLogPublicAsyncSuccess.bind(this, {
        loaded,
        limit,
      }),
    )
    .exec();
};
const onGetProjectsLogPublicAsyncSuccess = (_, { data }) => {
  loadingProjectsLogPublic.value = false;
  pagination.offset += data.length;
  items.value.push(...data);
};
const loadProjectsLogPublicAsync = (action) => {
  getProjectsLogPublicAsync(action);
};
const goToProject = (item) => {
  if (isUserRegistrated.value) {
    router.push({
      name: 'loan',
      params: { id: item.projectId },
    });
  } else {
    router.push({
      name: 'investorCheckData',
    });
  }
};
const findBySearchString = () => {
  items.value = [];
  pagination.reset();
  getProjectsLogPublicAsync();
};

const reset = () => {
  pagination.reset();
  items.value = [];
};
</script>

<style lang="scss" scoped>
.block_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  @media (max-width: $size_767) {
    font-size: 14px;
    line-height: 20px;
  }
}

.analitics-find-number::-webkit-input-placeholder {
  line-height: 15px;
}
.analitics-find-number:-moz-placeholder {
  line-height: 15px;
}

:deep(.analytics-event) {
  @media (max-width: $size_767) {
    margin: 10px 0px;
  }
}

.search_input {
  padding-left: 30px;
  background-image: url('@img/icons/loupe.svg') !important;
  background-repeat: no-repeat !important;
  background-position: left 10px center !important;
  box-shadow: none !important;
}

.no_history {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.analitics-find-number {
  width: 417px;
  background: transparent;
  @media (max-width: $size_767) {
    width: 100%;
  }
}

.item {
  @media (max-width: $size_767) {
    margin-bottom: 20px;
  }
}
:deep(.property) {
  margin-right: 5px;
  text-transform: none;
}
</style>
